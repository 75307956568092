import React from "react"
import axios from "axios"
import InnerWrapper from "./innerWrapper"
import styles from "./scss/contact.module.scss"

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    success: false,
    failure: false,
  }

  handleSubmit = evt => {
    evt.preventDefault()
    const axiosConfig = {
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    axios
      .post(
        "/.netlify/functions/sendEmail",
        {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
        },
        axiosConfig
      )
      .then(
        () =>
          this.setState({
            success: true,
            name: "",
            email: "",
            message: "",
          }),
        setTimeout(() => {
          this.setState({
            success: false,
          })
        }, 5000)
      )
      .catch(
        () => this.setState({ failure: true }),
        setTimeout(() => {
          this.setState({
            failure: false,
          })
        }, 5000)
      )
  }

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }
  render() {
    return (
      <section className={styles.contact} id="contact">
        <InnerWrapper width={800}>
          <h2>Get in touch</h2>
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={this.state.name}
              required
              onChange={this.handleChange}
            />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              required
              value={this.state.email}
              onChange={this.handleChange}
            />
            {/*<label htmlFor="subject">Subject</label>
             <select
              onChange={this.handleChange}
              name="subject"
              id="subject"
              value={this.state.subject}
            >
              <option value="Choose an option">Choose an option</option>
              <option value="Starter Plan">Starter Plan</option>
              <option value="Dynamic Plan">Dynamic Plan</option>
              <option value="Growth Plan">Growth Plan</option>
              <option value="Other">Other</option>
            </select> */}
            <label htmlFor="message">Your message</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              required
              value={this.state.message}
              onChange={this.handleChange}
            />
            <button className="button" type="submit">
              Submit
            </button>
            <div className={styles.infoMessage}>
              {this.state.success ? "Thank you for your message" : ""}
            </div>
          </form>
        </InnerWrapper>
      </section>
    )
  }
}
export default Contact
